import React, { useState } from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import styled from "styled-components";
import {
  mainWhite,
  mainNavy,
  innerWidth,
  mainViolet,
  screen,
} from "../../components/common/variables";
import Nav from "../../components/nav";
import StyledButton from "../../components/styled-button";
import ProgressBar from "../../components/progress-bar";
import BottomNav from "../../components/bottom-nav";
import { MdClear } from "react-icons/md";
import { motion } from "framer-motion";

// motion framer animation props value
const variantsContainer = {
  open: { opacity: 1 },
};

const variantsList = {
  open: {
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.07,
    },
  },
  close: {
    transition: {
      staggerChildren: 0.05,
      staggerDirection: -1,
    },
  },
};

const variantsItem = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  close: {
    y: 29,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

const Wrapper = styled(motion.div)`
  background: ${mainNavy};
  position: relative;
  overflow: auto;
  color: ${mainWhite};
  height: calc(100vh - 96px);
  padding: 0 0 50px 0;

  .inner-wrapper {
    max-width: ${innerWidth};
    margin: 0 auto;
    padding: 0 32px;
    display: flex;
    @media ${screen.xsmall} {
      padding: 140px 48px 0 48px;
    }
    @media ${screen.medium} {
      padding: 140px 0 0 0;
    }

    .texts {
      margin: 0 60px 0 0;
      max-width: 600px;
      width: 100%;

      h1 {
        color: ${mainViolet};
        font-size: 2.3rem;
        font-weight: 700;
        margin: 0 0 18px 0;
      }

      p {
        font-size: 1.12rem;
        margin: 0 0 16px 0;
      }

      ul {
        padding: 0 0 0 50px;
        li {
          list-style-type: disc;
          font-size: 1.12rem;
          margin: 0 0 14px 0;
        }
      }
    }

    .check-wrapper {
      margin: 88px 0 0 0;

      .check-list {
        display: flex;
        flex-wrap: wrap;
        padding: 10px 0 0 0;

        &__each {
          width: 50%;
          display: flex;
          align-items: flex-start;
          margin: 0 0 14px 0;
          padding: 0 20px 0 0;

          .box {
            border: 2px #a8acb2 solid;
            width: 26px;
            height: 26px;
            margin: 6px 20px 0 0;

            cursor: pointer;
            position: relative;

            svg {
              font-size: 1.6rem;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
          }

          .label {
            font-size: 1.4rem;
            width: 90%;
          }
        }
      }

      .btn {
        display: flex;
        justify-content: flex-end;
        margin: 50px 0 0 0;

        &--disabled {
          a {
            background: #848b93;
            border: 2px #848b93 solid;
            color: #a5a9ad;
            pointer-events: none;
          }
        }
      }
    }
  }
`;

const DiscoverTwo = ({ data }) => {
  const [checkList, setCheckList] = useState([]);

  const handleCheck = (val) => {
    if (checkList.indexOf(val) === -1) {
      setCheckList([...checkList, val]);
    } else {
      const filtered = checkList.filter((item) => item !== val);
      setCheckList(filtered);
    }
  };

  const isChecked = (val) => {
    if (checkList.indexOf(val) !== -1) {
      return <MdClear />;
    } else {
      return null;
    }
  };

  console.log(checkList);
  return (
    <Layout>
      <SEO title="Modernize - 2" />
      <Nav />
      <Wrapper initial="close" animate={"open"} variants={variantsContainer}>
        <motion.div variants={variantsList}>
          <ProgressBar>
            <h2 className="title">MODERNIZE</h2>
            <div className="bars">
              <p className="selected">1. Suitability</p>
              <p className="selected">2. Conversion</p>
              <p className="selected selected--not-selected">3. Report</p>
            </div>
          </ProgressBar>
          <div className="inner-wrapper">
            <motion.div variants={variantsItem} className="texts">
              <h1>Select applications to modernize to MSIX.​</h1>
              <p>
                It allows IT Pros and Developers to deliver a user centric
                solution, while reducing cost and management effort.​
              </p>
            </motion.div>
            <motion.div variants={variantsItem} className="check-wrapper">
              <ul className="check-list">
                <li className="check-list__each">
                  <div
                    className="box"
                    onClick={() => handleCheck("Application 1")}
                  >
                    {isChecked("Application 1")}
                  </div>
                  <p className="label">Application 1</p>
                </li>
                <li className="check-list__each">
                  <div
                    className="box"
                    onClick={() => handleCheck("Application 2")}
                  >
                    {isChecked("Application 2")}
                  </div>
                  <p className="label">Application 2</p>
                </li>
                <li className="check-list__each">
                  <div
                    className="box"
                    onClick={() => handleCheck("Application 3")}
                  >
                    {isChecked("Application 3")}
                  </div>
                  <p className="label">Application 3</p>
                </li>
                <li className="check-list__each">
                  <div
                    className="box"
                    onClick={() => handleCheck("Application 4")}
                  >
                    {isChecked("Application 4")}
                  </div>
                  <p className="label">Application 4</p>
                </li>
                <li className="check-list__each">
                  <div
                    className="box"
                    onClick={() => handleCheck("Application 5")}
                  >
                    {isChecked("Application 5")}
                  </div>
                  <p className="label">Application 5</p>
                </li>
                <li className="check-list__each">
                  <div
                    className="box"
                    onClick={() => handleCheck("Application 6")}
                  >
                    {isChecked("Application 6")}
                  </div>
                  <p className="label">Application 6</p>
                </li>
                <li className="check-list__each">
                  <div
                    className="box"
                    onClick={() => handleCheck("Application 7")}
                  >
                    {isChecked("Application 7")}
                  </div>
                  <p className="label">Application 7</p>
                </li>
              </ul>

              <StyledButton
                className={checkList.length ? "btn" : "btn btn--disabled"}
                to="/modernize/3/"
              >
                RUN MSIX CONVERSION
              </StyledButton>
            </motion.div>
          </div>
        </motion.div>

        <BottomNav
          leftLabel={"BACK"}
          leftLink={"/modernize/1/"}
          rightLabel={"BACK TO START"}
          rightLink={"/"}
        />
      </Wrapper>
    </Layout>
  );
};

export default DiscoverTwo;
